// import { Masonry as Component } from "react-plock";

const { Masonry: Component } = require("react-plock");

type TProps = {
  items: any[];
  config: {
    columns: number[];
    gap: number[];
    media: number[];
  };
  render: (item: any, index: number) => any;
};

const Masonry = (props: TProps) => {
  return <Component {...props} />;
};

export default Masonry;
