'use client';

import React from 'react';

import Masonry from '@repo/ui/masonry';

import ReviewItem from '../ReviewItem';

type TProps = {
  reviews: any;
};

const ReviewsMasonry = (props: TProps) => {
  const { reviews } = props;

  return (
    <Masonry
      items={reviews}
      config={{
        columns: [1, 2, 3],
        gap: [12, 24, 24],
        media: [640, 1280, 1280],
      }}
      render={(review: any, index: number) => (
        <ReviewItem key={`${review.documentId}-${index}`} review={review} />
      )}
    />
  );
};

export default ReviewsMasonry;
