import React from 'react';

import { Avatar, AvatarFallback, AvatarImage } from '@repo/ui/avatar';

import { handleGenerateInitialsFromName } from 'helpers/format';
import { handleUseStrapiImageUrl } from 'helpers/strapi';

import FadeInAnimation from 'components/fade-in-animation';

import { styles } from '.';

type TProps = {
  review: any;
};

const ReviewItem = (props: TProps) => {
  const { review } = props;

  const handleGenerateDescription = (_?: string, location?: string) => {
    const segments = [];

    // if (date) {
    //   segments.push(date);
    // }

    if (location) {
      segments.push(location);
    }

    return segments.join(', ');
  };

  return (
    <FadeInAnimation>
      <div className={styles.container}>
        <div className={styles.body}>
          <p className="!text-sm !leading-relaxed !font-light">
            {review.content}
          </p>
        </div>
        <div className={styles.foot.container}>
          <Avatar style={{ width: '45px', height: '45px' }}>
            <AvatarImage
              src={handleUseStrapiImageUrl(review?.image?.url ?? '')}
              alt={review.image?.alternativeText}
            />
            <AvatarFallback>
              {handleGenerateInitialsFromName(review.author)}
            </AvatarFallback>
          </Avatar>
          <div className={styles.foot.body}>
            <p className={styles.foot.title}>{review.author}</p>
            <p className={styles.foot.description}>
              {handleGenerateDescription(
                review?.date
                  ? new Date(review?.date).toLocaleDateString('nl')
                  : undefined,
                review?.location,
              )}
            </p>
          </div>
        </div>
      </div>
    </FadeInAnimation>
  );
};

export default ReviewItem;
