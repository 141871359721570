'use client';

import { motion } from 'motion/react';

import { toClassName } from 'helpers/format';

type TProps = {
  as?: 'li' | 'div' | 'section' | 'h1';

  className?: string;

  children: any;
};

const FadeInAnimation = (props: TProps) => {
  const as = props?.as ?? 'div';

  let component;

  const config = {
    className: toClassName('fade-in-animation-fallback', props?.className),
    initial: { opacity: 0 },
    whileInView: { opacity: 1 },
    transition: { duration: 0.3, delay: 0.3, ease: 'easeOut' },
    viewport: { once: true },
    children: props?.children,
  };

  switch (as) {
    case 'li':
      component = <motion.li {...config} />;
      break;
    case 'div':
      component = <motion.div {...config} />;
      break;
    case 'section':
      component = <motion.section {...config} />;
      break;
    case 'h1':
      component = <motion.h1 {...config} />;
      break;
  }

  return component;
};

export default FadeInAnimation;
