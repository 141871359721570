import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/fade-in-animation/fade-in-animation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/navbar/_components/Banner/Banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/navbar/_components/NavigationMenuSection/NavigationMenuSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/navbar/_components/SideMenuSection/SideMenuSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/components/strapi-blocks-renderer/strapi-blocks-renderer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/views/reviews/Landing/_components/ReviewsMasonry/ReviewsMasonry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path0/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/node_modules/@radix-ui/react-accordion/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/avatar/avatar.tsx");
